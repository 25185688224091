import React from 'react'
import { graphql } from 'gatsby'
import { BlogView } from '@views'

const ContentfulPage = (props) => {
  return <BlogView props={props} />
}

export const blogPostQuery = graphql`
  query blogPostQuery($pageId: String!) {
    contentfulBlogPost(id: { eq: $pageId }) {
      id
      title
      publishDate
      urlSlug
      tag
      heroImage {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          formats: [WEBP, AUTO]
          aspectRatio: 1.75
        )
      }
      articleBody {
        raw
      }
      articleCtaButton {
        id
        text
        color
        buttonType {
          __typename
          ... on ContentfulInternalLink {
            id
            name
            link {
              urlSlug
            }
          }
          ... on ContentfulExternalLink {
            id
            name
            externalLinkUrl {
              externalLinkUrl
            }
          }
        }
      }
      previousArticle {
        urlSlug
        publishDate
      }
      nextArticle {
        urlSlug
        publishDate
      }
      topPicksTitle
      topPicks {
        titleText
        paragraphText {
          paragraphText
        }
        id
        photoSide
        photo {
          title
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            formats: [WEBP, AUTO]
            placeholder: TRACED_SVG
            aspectRatio: 1.1
          )
        }
        linkText
        link {
          link
        }
      }
    }
    contentfulFlashMessage(name: { eq: "Flash Message" }) {
      name
      bannerLink
      text {
        raw
      }
    }
  }
`

export default ContentfulPage
